import React from "react";

type Props = {
  className?: string;
};

export const MainPageFooter: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={
        "flex flex-col lg:flex-row pt-8 pb-16 w-full px-6 lg:pt-8 lg:pb-12 gap-8 lg:gap-48 justify-center bg-[#E9EFC6]"
      }
    >
      <div className="flex flex-col lg:h-16 lg:flex-row lg:justify-start items-start lg:items-center gap-8">
        <div className="flex flex-row items-center gap-4">
          <img
            src="/img/fb.svg"
            alt="fb"
            className="w-[66px] hover:opacity-70 cursor-pointer"
            onClick={() =>
              window.open("https://www.facebook.com/GutesFinden", "_self")
            }
          />
          <img
            src="/img/yt.svg"
            alt="yt"
            className="w-[66px] hover:opacity-70 cursor-pointer"
            onClick={() =>
              window.open(
                "https://youtube.com/channel/UCZx1JkW7PMf8tqAZSX5r9Pw",
                "_self"
              )
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center gap-1 lg:gap-8">
          <p
            className="hover:opacity-70 hover:underline underline-offset-4 text-text font-normal text-base lg:text-lg cursor-pointer"
            onClick={() =>
              window.open("https://www.gutesfinden.at/ueberuns/", "_self")
            }
          >
            Über uns
          </p>
          <p
            className="hover:opacity-70 hover:underline underline-offset-4 text-text font-normal text-base lg:text-lg cursor-pointer"
            onClick={() =>
              window.open("https://www.gutesfinden.at/downloads/", "_self")
            }
          >
            Downloads & Presse
          </p>
          <p
            className="hover:opacity-70 hover:underline underline-offset-4 text-text font-normal text-base lg:text-lg cursor-pointer"
            onClick={() =>
              window.open("https://www.gutesfinden.at/impressum/", "_self")
            }
          >
            Impressum
          </p>
          <p
            className="hover:opacity-70 hover:underline underline-offset-4 text-text font-normal text-base lg:text-lg cursor-pointer"
            onClick={() =>
              window.open("https://www.gutesfinden.at/datenschutz/", "_self")
            }
          >
            Datenschutz
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-0.5 lg:gap-0.5 items-start mt-3">
        <h2
          className="text-text font-familyBold"
          style={{
            fontSize: "30px",
            lineHeight: "40px",
            letterSpacing: "0.05em",
          }}
        >
          Kontakt
        </h2>
        <p className="text-text font-normal text-base lg:text-lg">
          Klimabündnis Oberösterreich
        </p>
        <p className="text-text font-normal text-base lg:text-lg">
          Südtirolerstraße 28/5
        </p>
        <p className="text-text font-normal text-base lg:text-lg">4020 Linz</p>
        <p className="text-text font-normal text-base lg:text-lg">
          T. 0732 / 77 26 52
        </p>
        <p className="text-text font-normal text-base lg:text-lg">
          gutesfinden@klimabuendnis.at
        </p>
      </div>
    </div>
  );
};
